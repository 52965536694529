import React from 'react'
import { Link } from 'gatsby'

import * as styles from './letter.module.css'

import DisplayFont from './display-font'

const Letter = ({ letter }) => {
  return (
    <div className={styles.letterContainer}>
      <Link to={`/${letter.slug}`}>
        <DisplayFont characterAlt={letter?.slug?.includes('-2')}>
          {letter.character}
        </DisplayFont>
      </Link>
    </div>
  )
}

export default Letter
