import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Layout from '../components/layout'
import Letter from '../components/letter'

import * as styles from './characters.module.css'

class Letters extends React.Component {
  render() {
    const characters = get(this, 'props.data.allContentfulCharacter.nodes')
    return (
      <Layout location={this?.props?.location}>
        <div className={styles.lettersGrid}>
        {characters.map((letter) => (
          <Letter letter={letter} key={letter.slug} />
        ))}
        </div>
      </Layout>
    )
  }
}

export default Letters

export const pageQuery = graphql`
  query LettersQuery {
    allContentfulCharacter(sort: {fields: [order, character]}) {
      nodes {
        character
        slug
      }
    }
  }
`
